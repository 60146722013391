._rootPortal:not(:empty) {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  overflow-y: scroll;
  overscroll-behavior: contain;
  -ms-scroll-chaining: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}

.component_root {
  height: 100%;
  width: 100%;

  & > .content {
    min-height: 100vh;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  & > * { flex-grow: 1; }
}
