.component {
  background-color: black;
  color: white;
  overflow-y: scroll;
  overscroll-behavior: contain;
  padding: var(--size-50) var(---size-30);
  -ms-scroll-chaining: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;

  & > * {/* FocusTrap */
    @media (--viewport-md) { height: 100%; }
  }
}

.component_rootContent {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
  min-height: 100%;

  & > .closeButtonLayout {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    top: var(--size-30);
    right: var(--size-20);

    @media (--viewport-md) {
      top: var(--size-70);
      right: var(--size-50);
    }
  }

  & > .content {
    min-width: 200px;
    flex-basis: 80%;
    max-width: 360px;
    @media (--viewport-md) {
      flex-basis: 100%;
      max-width: 1400px;
    }
    @media (--viewport-lg) { flex-basis: 70%; }
  }
}

.content {
  @media (--viewport-md) {
    display: flex;
    align-items: center;
  }

  & > .menus {
    position: relative;
    top: -40px;
    margin-left: var(--size-20);
    margin-right: var(--size-20);

    @media (--viewport-md) {
      top: 0;
      left: -5%;
      margin-right: 0;
      flex-basis: 55%;
    }
  }

  & > .videoLayout {
    @media (--viewport-md) {
      flex-basis: 50%;
      min-width: 360px;
    }
  }
}

.componentCloseButton {
  fill: white;
  transition: transform 200ms ease-out;

  &:hover,
  &:focus { transform: rotate(90deg); }
}

.componentVideo {
  color: black;
}

.componentMainMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: var(--font-family-condensed);
  letter-spacing: var(--letter-spacing-compact-xl);
  font-weight: var(--font-weight-condensed-800);
  text-transform: uppercase;
  line-height: 0.85;
  font-size: var(--font-size-40);

  @media (--viewport-md) {
    font-size: var(--font-size-70);
  }
}

.componentPageLinkBase {
  transition: opacity 200ms ease-out;
  white-space: nowrap;
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus { opacity: 0.8; }
}

.menus {
  & > :not(:last-child) {
    margin-bottom: var(--size-20);
    @media (--viewport-md) { margin-bottom: var(--size-30); }
  }

  & > .caseMenuLayout {
    @media (--viewport-md) {
      margin-left: auto;
      max-width: 400px;
    }
  }
}
