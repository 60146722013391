:root {
  --container-xsm: 500px;
  --container-sm: 620px;
  --container-md: 800px;
  --container-lg: 1280px;
  --container-xl: 1400px;
  --container-xxl: 1600px;
  --container-xxxl: 1920px;
}

:export {
  container-lg: 1280px;
}
