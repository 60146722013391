:root {
  /* font-families */
  --font-family-base: 'Space Grotesk', sans-serif;
  --font-family-condensed: 'Barlow Condensed', sans-serif;

  /* font-sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc(
    (var(--font-size-growth) * 100vw) -
    (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit))
  );

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-35: 35px;
  --font-size-40: 40px;
  --font-size-45: 45px;
  --font-size-60: 60px;
  --font-size-70: 70px;
  --font-size-80: 80px;
  --font-size-100: 100px;
  --font-size-150: 150px;

  --font-size-12-16: clamp(12px, calc(12px + 4 * var(--m)), 16px);
  --font-size-14-18: clamp(14px, calc(14px + 4 * var(--m)), 18px);
  --font-size-18-40: clamp(18px, calc(18px + 22 * var(--m)), 40px);
  --font-size-32-40: clamp(32px, calc(32px + 8 * var(--m)), 40px);
  --font-size-20-24: clamp(20px, calc(20px + 4 * var(--m)), 24px);
  --font-size-20-28: clamp(20px, calc(20px + 8 * var(--m)), 28px);
  --font-size-32-45: clamp(32px, calc(32px + 13 * var(--m)), 45px);
  --font-size-35-60: clamp(35px, calc(35px + 25 * var(--m)), 60px);
  --font-size-40-80: clamp(35px, calc(40px + 40 * var(--m)), 80px);
  --font-size-60-100: clamp(60px, calc(60px + 40 * var(--m)), 100px);
  --font-size-70-130: clamp(35px, calc(40px + 40 * var(--m)), 80px);
  --font-size-70-200: clamp(70px, calc(70px + 130 * var(--m)), 200px);
  --font-size-80-200: clamp(80px, calc(80px + 120 * var(--m)), 200px);
  --font-size-80-300: clamp(80px, calc(80px + 220 * var(--m)), 300px);

  /* base font weights */
  --font-weight-base-300: 300;
  --font-weight-base-400: 400;
  --font-weight-base-500: 500;

  /* condensed font weights */
  --font-weight-condensed-500: 400;
  --font-weight-condensed-800: 800;

  /* compact */
  --letter-spacing-compact: -0.012em;
  --letter-spacing-compact-xl: -0.012em;
  --line-height-compact: 0.9;
  --line-height-compact-md: 1.2;
}
