:root {
  --size-2: 2px;
  --size-5: 5px;
  --size-10: 10px;
  --size-15: 15px;
  --size-20: 20px;
  --size-25: 25px;
  --size-30: 30px;
  --size-40: 40px;
  --size-50: 50px;
  --size-60: 60px;
  --size-70: 70px;
  --size-80: 80px;
  --size-90: 90px;
  --size-100: 100px;
  --size-120: 120px;
  --size-150: 150px;
  --size-180: 180px;
  --size-200: 200px;
}

:export {
  slideImageSizeXs: 300;
  slideImageSizeMd: 370;
}
